import React from "react"
import { withPrefix, Link } from "gatsby"
import { pl, en, partners } from "@content/homepage/partners.yaml"

const Partners = ({isEn}) => {
  const translation = isEn ? en : pl

  return (
    <div className="whitebg">
      <section
        className="section padding wow fadeInUp"
        data-wow-delay="0.5s"
        style={{
          visibility: "hidden",
        }}
      >
        <div className="row">
          <div className="large-12 medium-12 small-12 columns">
            <h2>{translation.title}</h2>
          </div>

          <div
            className="large-12 medium-12 small-12 columns"
            data-equalizer="partnerlogo"
            data-resize="partnerlogo"
            data-mutate="partnerlogo"
            data-events="resize"
          >
            <div
              className="row partners-grid"
              data-equalizer="partnerlogo"
              data-resize="partnerlogo"
              data-mutate="partnerlogo"
              data-events="resize"
            >
              <div className="large-3 medium-12 small-12 columns text-center ">
                <div
                  className="logo"
                  data-equalizer-watch="partnerlogo"
                  style={{ height: "auto" }}
                >
                  {partners.map((partner, key) => (
                    <span key={key}>
                      <a href={partner.link} rel="nofollow">
                        <img
                          alt={partner.image.alt}
                          src={withPrefix(partner.image.url)}
                        />
                      </a>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Partners
