import React from "react"
import { Link, withPrefix } from "gatsby"
import { pl, en, equipment, image } from "@content/homepage/sasa-box.yaml"

const SasaBox = ({isEn}) => {
  const translation = isEn ? en : pl

  return (
    <section
      id="sprzet"
      className="section padding-bottom padding-top50"
      data-equalizer="sprzet-tekst"
      data-resize="sprzet-tekst"
      data-mutate="sprzet-tekst"
      data-events="resize"
    >
      <div className="row">
        <div className="large-5 medium-4 small-12 columns littletop">
          <div
            className="display-table small-block"
            data-equalizer-watch="sprzet-tekst"
          >
            <div className="display-table-cell">
              <p>
                <Link to={translation.link}>
                  <img src={withPrefix(image.url)} alt={image.alt} />
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="medium-8 columns show-for-medium-only">
          <div className="h2">{translation.title}</div>
          <p>{translation.description}</p>
        </div>
        <div className="large-7 medium-12 small-12 columns">
          <div
            className="display-table small-block"
            data-equalizer-watch="sprzet-tekst"
          >
            <div className="display-table-cell">
              <div className="hide-for-medium-only">
                <h2>{translation.title}</h2>
                <p>{translation.description}</p>
              </div>
              <p className="red">{translation.subdescription}</p>

              <div
                className="row small-up-2 medium-up-5 large-up-5"
                data-equalizer="icons"
                data-resize="icons"
                data-mutate="icons"
                data-events="mutate"
              >
                {equipment.map((item, key) => (
                  <div className="column icons text-center" key={key}>
                    <p className="display-table" data-equalizer-watch="icons">
                      <span className="display-table-cell">
                        <img
                          src={withPrefix(item.image.url)}
                          alt={item.image.alt}
                        />
                      </span>
                    </p>
                    <div className="h2" id="count_1">
                      {item.amount}
                    </div>
                  </div>
                ))}
              </div>
              <p className="text-center medium-text-left">
                <Link to={translation.link} className="button nobg red">
                  {translation.link_label}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SasaBox
