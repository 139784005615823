import React from "react"
import { withPrefix } from "gatsby"
import SasaWhite from "@svg/sasa-white"
import { pl, en } from "@content/homepage/hero.yaml"

const Hero = ({isEn}) => {
  const content = isEn ? en : pl
  return (
    <div data-scrollmagic-pin-spacer="" className="scrollmagic-pin-spacer">
      <section id="start_screen">
        <div className="inner">
          <div
            className="photo_greyscale grayscale"
            style={{
              backgroundImage: `url(${withPrefix("images/homepage/main.jpg")})`,
            }}
          ></div>
          <div className="square wow fadeIn" data-wow-delay="0.5s" id="square" style={{visibility: 'hidden'}}>
            <div className="inner">
              <div className="text trans">
                <p className="logo margin-bottom10">
                  <SasaWhite />
                </p>
                {!isEn && (
                  <p className="logo-percent margin-bottom10">
                    <a
                      href="http://bit.ly/2E8LP70"
                      target="_blank"
                      rel="dofollow"
                    >
                      <img
                        src={withPrefix("images/homepage/hero/1percent.png")}
                        alt="1% podatku"
                      />
                    </a>
                  </p>
                )}
                <div
                  className="text-inner"
                  data-equalizer="squareheighttext"
                  data-equalize-on-stack="true"
                >
                  <div id="top_desc" data-equalizer-watch="squareheighttext">
                    <div className="inner">
                      <p>
                        <em>basic healthcare worldwide</em>
                      </p>
                    </div>
                  </div>
                  <div
                    id="top_desc_feat"
                    data-equalizer-watch="squareheighttext"
                  >
                    <div className="inner">
                      <p>{content.description}</p>
                    </div>
                  </div>
                </div>
                <div className="arrows white">
                  <i className="fa fa-angle-double-down"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Hero
