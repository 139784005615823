import React from "react"
import { Link, withPrefix } from "gatsby"
import { en, pl, image } from "@content/homepage/local.yaml"

const Local = ({isEn}) => {
  const translation = isEn ? en : pl
  return (
    <section
      className="section padding-bottom wow fadeInUp"
      data-wow-delay="0.5s"
      style={{
        visibility: "hidden",
      }}
    >
      <div
        className="row"
        data-equalizer="pomoc-tekst"
        data-resize="pomoc-tekst"
        data-mutate="pomoc-tekst"
        data-events="mutate"
      >
        <div className="large-6 medium-7 small-12 small-push-12 large-push-6 columns">
          <div
            className="display-table small-block"
            data-equalizer-watch="pomoc-tekst"
          >
            <div className="display-table-cell">
              <h2>{translation.title}</h2>
              <p className="p1">{translation.description}</p>
              <p className="text-center medium-text-left">
                <Link to={translation.link} className="button nobg red">
                  {translation.link_label}
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="large-6 medium-5 small-12 small-pull-12 large-pull-6 columns">
          <div
            className="display-table small-block"
            data-equalizer-watch="pomoc-tekst"
          >
            <div className="display-table-cell">
              <p>
                <Link to={translation.link}>
                  <img src={withPrefix(image.url)} alt={image.alt} />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Local
