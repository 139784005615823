import React from "react"
import { Link } from "gatsby"
import {
  pl,
  en,
  image_top,
  image_bottom,
} from "@content/homepage/voluntary.yaml"
import { withPrefix } from "gatsby-link"

const Voluntary = ({isEn}) => {
  const translation = isEn ? en : pl

  return (
    <section
      className="section padding-bottom wow fadeInUp"
      data-wow-delay="0.5s"
      style={{
        visibility: "hidden",
      }}
    >
      <div className="row">
        <div className="large-4 medium-12 small-12 columns">
          <h2>{translation.title}</h2>
          <p className="p1">{translation.description}</p>
          <p className="text-center medium-text-left">
            <Link to={translation.link} className="button nobg red">
              {translation.link_label}
            </Link>
          </p>
        </div>

        <div className="large-8 medium-12 small-12 columns">
          <div
            className="row"
            data-equalizer="photo1"
            data-resize="photo1"
            data-mutate="photo1"
            data-events="mutate"
          >
            <div className="large-8 medium-8 small-12 columns">
              <div
                className="display-table small-block"
                data-equalizer-watch="photo1"
              >
                <div className="display-table-cell">
                  <p>
                    <Link to={translation.link}>
                      <img
                        src={withPrefix(image_top.url)}
                        alt={image_top.alt}
                      />
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="large-4 medium-4 small-12 columns">
              <div
                className="display-table small-block"
                data-equalizer-watch="photo1"
              >
                <div className="display-table-cell">
                  <p className="red">
                    <em>{translation.label_top}</em>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="photo2_top">
            <div
              className="row"
              data-equalizer="photo2"
              data-resize="photo2"
              data-mutate="photo2"
              data-events="mutate"
            >
              <div className="large-8 medium-8 small-12 small-push-12 medium-push-4 large-push-4 columns">
                <div
                  className="display-table small-block"
                  data-equalizer-watch="photo2"
                >
                  <div className="display-table-cell">
                    <p>
                      <Link to={translation.link}>
                        <img
                          src={withPrefix(image_bottom.url)}
                          alt={image_bottom.alt}
                        />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="large-4 medium-4 small-12 small-pull-12 medium-pull-8 large-pull-8 columns text-left medium-text-right">
                <div
                  className="display-table small-block"
                  data-equalizer-watch="photo2"
                >
                  <div className="display-table-cell">
                    <p className="red">
                      <em>{translation.label_bottom}</em>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Voluntary
