import React from "react"
import { Link } from "gatsby"
import PitSvg from "@svg/pit"
import { title, description } from "@content/homepage/pit.yaml"

const PIT = () => (
  <section className="section-one-percent">
    <div className="row">
      <div className="large-12 columns">
        <div className="inner">
          <div className="content">
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
          <div className="link">
            <div className="one-procent-button">
              <PitSvg />
              <Link to="http://bit.ly/2E8LP70" rel="dofollow" target="_blank">
                ROZLICZ Z NAMI PIT!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default PIT
