import { Link } from "gatsby"
import React from "react"
import "./meet.css"
import { pl, en } from "@content/homepage/meet.yaml"

const Meet = ({isEn}) => {
  const translation = isEn ? en : pl

  return (
    <section className="section greybg">
      <div
        className="greybg-inner wow fadeIn"
        data-wow-delay="0.5s"
        style={{ visibility: "hidden" }}
      >
        <h2 className="white">{translation.title}</h2>
        <Link to={translation.link} className="button background">
          {translation.link_label}
        </Link>
      </div>
      <div className="photobg"></div>
    </section>
  )
}

export default Meet
