import React from "react"
import {graphql, Link, withPrefix} from "gatsby"
import {pl, en} from "@content/homepage/blog.yaml"
import {formatDistanceToNow} from "date-fns";
import * as dateLocale from "date-fns/locale"

const Blog = ({posts, isEn}) => {
  const translation = isEn ? en : pl
  posts.sort(function (a, b) {
    return new Date(b.frontmatter.date) - new Date(a.frontmatter.date)
  })
  const first = posts[0].frontmatter
  const second = posts[1].frontmatter
  const third = posts[2].frontmatter
  const fourth = posts[3].frontmatter

  return (
    <section
      className="section columns-row padding wow fadeInUp"
      data-wow-delay="0.5s"
      style={{
        visibility: "hidden",
      }}
    >
      <div
        className="row"
        data-equalizer="news"
        data-resize="news"
        data-mutate="news"
        data-events="mutate"
      >
        <div className="large-12 medium-12 small-12 columns">
          <h2>{translation.title}</h2>
        </div>

        <div className="large-4 medium-9 small-12 columns">
          <CardJsx slug={first.slug}
                   title={first.title}
                   date={first.date}
                   thumbnail={first.thumbnail}/>
        </div>

        <div className="large-2 medium-3 columns hide-for-small-only">
          <div className="news-icon" data-equalizer-watch="news">
            <div className="display-table text-center">
              <div className="display-table-cell">
                <img
                  src={withPrefix("images/homepage/blog/samolot_bialy.svg")}
                  alt="samolot sasa"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="large-4 medium-9 small-12 columns">
          <CardJsx slug={second.slug}
                   title={second.title}
                   date={second.date}
                   thumbnail={second.thumbnail}/>
        </div>

        <div className="large-2 medium-3 columns hide-for-small-only">
          <div className="news-icon" data-equalizer-watch="news">
            <div className="display-table text-center">
              <div className="display-table-cell">
                <img
                  className="smaller"
                  src={withPrefix("images/homepage/blog/serce_bialy.svg")}
                  alt="pomoc dla potrzebujących"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="large-2 medium-3 columns hide-for-small-only">
          <div className="news-icon" data-equalizer-watch="news">
            <div className="display-table text-center">
              <div className="display-table-cell">
                <img
                  className="smaller"
                  src={withPrefix("images/homepage/blog/serce_bialy.svg")}
                  alt="pomoc dla potrzebujących"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="large-4 medium-9 small-12 columns">
          <CardJsx slug={third.slug}
                   title={third.title}
                   date={third.date}
                   thumbnail={third.thumbnail} />
        </div>

        <div className="large-2 medium-3 columns hide-for-small-only">
          <div className="news-icon" data-equalizer-watch="news">
            <div className="display-table text-center">
              <div className="display-table-cell">
                <img
                  className="smaller"
                  src={withPrefix("images/homepage/blog/strzykawka_bialy.svg")}
                  alt="strzykawka pomoc lekarska"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="large-4 medium-9 small-12 columns">
          <CardJsx slug={fourth.slug}
                   title={fourth.title}
                   date={fourth.date}
                   thumbnail={fourth.thumbnail} />
        </div>
      </div>

      <div className="row">
        <div className="large-12 medium-12 small-12 columns text-center medium-text-right">
          <Link to={translation.link} className="button nobg border red">
            {translation.link_label}
          </Link>
        </div>
      </div>
    </section>
  )
}

const CardJsx = ({slug, title, date, thumbnail}) => (
  <article className="news-small " data-equalizer-watch="news">
    <Link
      to={slug}
      className="inner display-table"
    >
      <div className="inner-content display-table-cell">
        <h5 className="title margin-bottom10" dangerouslySetInnerHTML={{__html: title}} />
        <p>
          <i className="fa fa-clock-o"></i>
          {formatDistanceToNow(new Date(date), {
            locale: dateLocale.pl,
          })}
        </p>
      </div>
    </Link>
    <div
      className="image"
      style={{
        backgroundImage: `url(${withPrefix(thumbnail)})`,
      }}
    ></div>
  </article>
)
export default Blog

