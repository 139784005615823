import React from "react"
import { Link, withPrefix } from "gatsby"
import Arrow from "@svg/arrow"
import Heart from "@svg/heart"
import { pl, en, background } from "@content/homepage/help.yaml"

const Help = ({isEn}) => {
  const translation = isEn ? en : pl
  return (
    <section id="site_position" className="section photos">
      <div
        className="square2 wow fadeIn"
        data-wow-delay="0.5s"
        style={{
          visibility: "hidden",
        }}
      >
        <div className="inner">
          <div className="text">
            <Link to={translation.link} className="rollover">
              <span className="underlined">
                <span className="roll-icon">
                  <Heart />
                  <Arrow />
                </span>
              </span>
              <h4 className="white big margin-bottom10">{translation.title}</h4>
              <p className="roll-show">{translation.description}</p>
            </Link>
          </div>
        </div>
      </div>
      <div className="row expanded collapse">
        <div className="large-6 medium-6 small-6 columns">
          <div
            className="photo"
            style={{ backgroundImage: `url(${withPrefix(background.url1)})` }}
          ></div>
        </div>
        <div className="large-6 medium-6 small-6 columns">
          <div
            className="photo"
            style={{ backgroundImage: `url(${withPrefix(background.url2)})` }}
          ></div>
        </div>
      </div>
    </section>
  )
}
export default Help
