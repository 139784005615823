import React from "react"

export default () => (
  <svg
    version="1.1"
    id="arrow"
    xmlns="http://www.w3.org/2000/svg"
    width="17.207px"
    height="35.079px"
    viewBox="0 0 17.207 35.079"
    enableBackground="new 0 0 17.207 35.079"
  >
    <path
      fill="#871A51"
      d="M4.87,35.079c0,0,9.407-11.824,11.259-14.572c0.719-1.067,1.027-1.979,1.078-2.776
		c-0.028-0.804-0.314-1.721-1.004-2.808c-1.782-2.797-10.88-14.86-10.88-14.86L0.454,0c0.033,0.637,11.293,15.133,11.523,15.401
		c0.654,0.76,0.917,1.543,0.922,2.271l0,0c-0.021,0.723-0.31,1.503-0.979,2.242C11.679,20.179,0.052,34.382,0,35.013L4.87,35.079z"
    ></path>
  </svg>
)
